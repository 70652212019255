import moment from "moment"
import React, {useEffect, useState} from "react"
import {Card, Col, Container, Row} from "react-bootstrap"
import {Jutsu} from "react-jutsu";
import {getStrTipoDoctor} from "../../utils/getStrTipoDoctor";
import CountdowSimple from "../utils/CountdowSimple";
import SubirArchivos from "./SubirArchivos";

function EnCita({cita, onComplete, handleAPI, setShowModalError, setErrorServer}){
    const [startMeeting, setStartMeeting] = useState(null)
    const [serverTime, setServerTime] = useState(null)
    const [clientTime, setClientTime] = useState(null)
    useEffect(()=>{
        console.log('vista cita')
        let minToPlus = 0
        let serverTime = moment(cita.currentTime, "DD/MM/YYYY HH:mm:ss")
        let startAppointment = moment(cita.scheduleDTO.dateTimeInit, "YYYY-MM-DDTHH:mm:ss")

        if(serverTime.isBefore(startAppointment)){
            let diff = startAppointment.diff(serverTime, "seconds")
            minToPlus = diff
        }

        minToPlus *= 1000

        setClientTime(Date.now())
        setServerTime(moment(cita.currentTime, "DD/MM/YYYY HH:mm:ss").valueOf()+minToPlus)      
        setStartMeeting(moment(cita.scheduleDTO.dateTimeEnd, "YYYY-MM-DDTHH:mm:ss").valueOf())
        
    }, [])
    
    return (
        <Container className="main pt-5rem">
            <Row>
                <Col xs="12" md={{span: 8, offset: 2}}>
                    <h1 className="color-bluerey-asis w-700 mt-0 d-block text-center">
                    {startMeeting &&
                    <CountdowSimple
                        date={startMeeting}
                        serverTime={serverTime}
                        setServerTime={setServerTime}
                        clientTime={clientTime}
                        setClientTime={setClientTime}
                        onClomplete={onComplete}
                        isReception={false}
                    />}
                    </h1>
                </Col>
            </Row>
            <Row className="mt-0">
                <Col xs="12" md={{span: 8, offset: 2}}>
                    <span className="text-secondary">
                        {`${getStrTipoDoctor(cita.speciality.id)}: ${cita.scheduleDTO.name_doctor}`}
                    </span>
                    <Card>
                        <Card.Body>
                            {cita && <Jutsu                    
                                roomName={cita ? cita.token_conference : "No_se_pudo_cargar_token"}
                                domain={cita?.url_conference}
                                subject={`${getStrTipoDoctor(cita.speciality.id)}: ${cita?.scheduleDTO?.name_doctor}`}
                                displayName={cita?.patientDTO?.name ? `${cita?.patientDTO?.name} ${cita?.patientDTO?.lastName}` : cita?.patientDTO?.email}
                                userName={cita?.patientDTO?.name ? `${cita?.patientDTO?.name} ${cita?.patientDTO?.lastName}` : cita?.patientDTO?.email}
                                loadingComponent={<p>en momentos inicia su consulta</p>} 
                                errorComponent={                                    
                                    <p> ¡Lo sentimos!,  Nuestros servidores se están actualizando por tal motivo no es posible realizar la video conferencia, 
                                        por favor refresque su navegador o contacte con nuestros números de la Mesa ayuda 55 8525 9573 para brindarle soporte. 
                                        Muchas gracias por la comprensión
                                    </p>
                                }
                                onJitsi={handleAPI}
                                containerStyles={{ width: '100%', height: '430px', margin: 'auto' }}
                            />}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col xs="12" md={{span: 8, offset: 2}}>
                    <SubirArchivos cita={cita} setShowModalError={setShowModalError} setErrorServer={setErrorServer}/>
                </Col>
            </Row>
        </Container>
    )
}

export default EnCita