import {Container} from "react-bootstrap";
import FormLab from "../components/checkup/FormLab";
import FormPet from "../components/checkup/FormPet";
import React from "react";
import usePatient from "../hook/usePatient";


function CheckUp(){
    const patient = usePatient()

    return (
        <Container className="main pt-5rem">
            {patient?.account?.clcuentaIke === 2591 ?
                <FormPet />
                :
                <FormLab />
            }
        </Container>
    );
}

export default CheckUp