import { useContext, useEffect, useState } from "react";
import { PASSWORD_IKE_CONNECT, USER_IKE_CONNECT } from "../api/contants";
import Post from "../api/post";
import { LOGIN } from "../api/routes";
import { authContext } from "../context/AuthProvider";

function useUserIke(){  
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [makeRequest, setMakeRequest] = useState(false)

    const {auth, setAuthData} = useContext(authContext)

    const getToken = () =>{
        let data = {
            username: USER_IKE_CONNECT,
            password: PASSWORD_IKE_CONNECT
        }
        Post({url: LOGIN, data: data, secure: false})
        .then(response => {
            console.log("Login")
            setToken(response.data.access_token)
            if (!auth.data) {
                let temp = {ike_token: response.data.access_token}
                setAuthData(temp)
            } else {
                let dataAuth = auth.data
                dataAuth.ike_token = response.data.access_token
                setAuthData(dataAuth)
            }

            setLoading(false)
        })
        .catch(error => {
            setError("error - no get token")
            setLoading(false)
        })
    }

    //efecto para checar si esta el user
    useEffect(()=>{
        //primero vemos si lo tenemos en localStorage
        if(auth && auth?.data?.hasOwnProperty("ike_token")){
            setToken(auth?.data?.ike_token)
            setLoading(false)
        }else{
            setMakeRequest(true)
        }
    }, [])    

    useEffect(()=>{
        if(makeRequest){
            getToken()
            setMakeRequest(false)
        }
    }, [makeRequest])

    return [token, error, loading];

}

export default useUserIke