import {Card, Col, Container, Image, Row} from "react-bootstrap";
import Slider from "react-slick";
import waitingroom1 from "../../img/principales/waitingroom.png";
import camara from "../../img/principales/camara.png";
import mic from "../../img/principales/mic.png";
import especialista from "../../img/principales/especialista.png";
import rayosx from "../../img/principales/rayosx.png";
import moment from "moment";
import React, {useEffect, useState} from "react";
import CountdowSimple from "../utils/CountdowSimple";

function Waiting({cita, onCompleteTimeStart}){
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1, 
        autoplay: true,
        autoplaySpeed: 5000,       
    };
    const [startMeeting, setStartMeeting] = useState(null)
    const [serverTime, setServerTime] = useState(null)
    const [clientTime, setClientTime] = useState(null)

    useEffect(()=>{
      setServerTime(moment(cita.currentTime, "DD/MM/YYYY HH:mm:ss").valueOf())
      setClientTime(Date.now())
      setStartMeeting(moment(cita.scheduleDTO.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").valueOf())
    }, [])
    
    
    
      return (
        <Container className="main pt-5rem">
            <Row className="mb-3">
                <Col className="text-center" xs="12" md={{span: 8, offset: 2}}>
                    <span
                        className="bg-bluerey-asis text-white d-flex justify-content-center align-items-center wh-80 m-auto rounded-circle">
                         <i className="icomoon icon-F__Online2 font-40"/>
                    </span>
                    <h4 className="font-30 color-bluerey-asis w-700 mt-2 mb-3">Sala de espera</h4>
                </Col>
            </Row>

            <Row>
              <Col xs="12" md={{span: 8, offset: 2}}>
                <Slider {...settings} className="slideHistory">
                  <Card className="text-center h-17rem">
                    <Card.Body>
                      <Image src={waitingroom1} width='100px' className='m-auto'/>
                      <h5 className="color-bluerey-asis w-700">¡Bienvenido!</h5>
                      <label className="color-bluerey-asis">El especialista <br /> estará contigo en:</label>
                      <h1 className="color-bluerey-asis w-700 mt-2">
                        {startMeeting && 
                        <CountdowSimple 
                            date={startMeeting} 
                            serverTime={serverTime}
                            setServerTime={setServerTime}
                            clientTime={clientTime}
                            setClientTime={setClientTime}
                            onClomplete={onCompleteTimeStart}
                            isReception={false}
                        />}
                      </h1>
                    </Card.Body>
                  </Card>
                  
                  <Card className="text-center h-17rem">
                    <Card.Body>
                      <h5 className="color-bluerey-asis w-700">Recuerda activar los permisos para:</h5>
                      <Row>
                        <Col xs='6'>
                          <Image src={camara} width='70px' className='m-auto'/>
                          <label className="color-bluerey-asis">Micrófono</label>
                        </Col>
                        <Col xs='6'>
                          <Image src={mic} width='70px' className='m-auto'/>
                          <label className="color-bluerey-asis">Cámara</label>
                        </Col>
                      </Row>
                      <label className="color-bluerey-asis mb-4 mt-3">Puedes hacerlo en configuraciones de tu dispositivo</label>
                    </Card.Body>
                  </Card>

                  <Card className="text-center h-17rem">
                    <Card.Body>
                      <Image src={especialista} width='100px' className='m-auto'/>
                      <h5 className="color-bluerey-asis w-700 mb-4 mt-2">Gracias por la espera</h5>
                      <label className="color-bluerey-asis">El especialista se conectará <br /> en un momento más</label>                
                    </Card.Body>
                  </Card>

                  <Card className="text-center h-17rem">
                    <Card.Body>
                      <Image src={rayosx} width='100px' className='m-auto'/>
                      <h6 className="color-bluerey-asis w-700">Recuerda que durante la consulta puedes compartir documentos como:</h6>                
                      <label className="color-bluerey-asis d-block">Estudios de laboratorio</label>
                      <label className="color-bluerey-asis d-block">Recetas</label>
                      <label className="color-bluerey-asis d-block">RX</label>
                    </Card.Body>
                  </Card>

                  <Card className="text-center h-17rem">
                    <Card.Body>
                      <Image src={especialista} width='100px' className='m-auto'/>
                      <h5 className="color-bluerey-asis w-700">Tu cámara y Micrófono se activarán</h5>                
                      <label className="color-bluerey-asis d-block">Cuando nuestro especialista inicie la consulta</label>
                    </Card.Body>
                  </Card>

                </Slider>
              </Col>
            </Row>
          
        </Container>
      );
}

export default Waiting