import React from "react"
import ContentLoader from "react-content-loader"

const NextAppointmentLoader = (props) => (
    <ContentLoader
        speed={1}
        width={400}
        height={200}
        viewBox="0 0 400 200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="25" y="52" rx="5" ry="5" width="150" height="10" />
        <rect x="25" y="75" rx="5" ry="5" width="100" height="10" />
        <rect x="25" y="117" rx="5" ry="5" width="50" height="10" />
        <rect x="25" y="16" rx="5" ry="5" width="220" height="10" />
        <rect x="25" y="138" rx="5" ry="5" width="50" height="10" />
        <rect x="86" y="138" rx="5" ry="5" width="20" height="10" />
        <rect x="119" y="138" rx="5" ry="5" width="50" height="10" />
        <rect x="5" y="164" rx="5" ry="5" width="300" height="1" />
        <rect x="114" y="182" rx="5" ry="5" width="100" height="10" />
    </ContentLoader>
)

export default NextAppointmentLoader