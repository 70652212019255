import {Card, Col, Container, Row} from "react-bootstrap";
import React from "react";
import usePatient from "../hook/usePatient";
import DiscountPeoples from "../components/descuentos/DiscountPeoples";
import DiscountPets from "../components/descuentos/DiscountPets";
import FooterMenu from "../components/FooterMenu";


function Discounts(props){

    const patient = usePatient()

    return (
        <>
            <Container className="main pt-5rem">
                <Col xs="12" md={{offset: 1, span: 10}}>
                <h4 className="text-primary-2 text-center mb-5">Descuentos</h4>
                <div className="position-sticky nav-discounts">
                    <Row>
                        <Col xs="12" md="8" className="my-1 order-1">
                            <Card className="card-bg-primary-2 text-white rounded-0 cmh-6rem">
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-center pt-3">
                                        <h6 className="fw-bold fs-6">Para hacer uso de tus descuentos es indispensable que te comunique con nosotros</h6>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs="12" md="4" className="my-1 order-3 order-md-2">
                            <a href={`tel:${props?.location?.phoneNumber}`} className="text-decoration-none">
                                <Card className="card-bg-primary-1 cmh-6rem shadow-lg text-white rounded-0">
                                    <Card.Body>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="me-1"><i className='icomoon icon-F__Telefono font-size-30px' /></div>
                                                <div><span>Llamar</span></div>
                                            </div>
                                    </Card.Body>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                </div>

                <Row>
                    {patient?.account?.clcuentaIke === 2591 ?
                        <DiscountPets />
                        :
                        <DiscountPeoples />
                    }
                </Row>
                </Col>
            </Container>
            <FooterMenu />
        </>
    )

}

export default Discounts
