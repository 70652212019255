import {IoClose} from "react-icons/io5";
import {Link} from "react-router-dom";
import React from "react";

function SlideMenu({showMenu, setShowMenu}){

    return (
        <div className={`slide  ${showMenu ? 'slide-on' : 'slide-off'}`}>
            <div className="slide-wrapper">
                <div className="slide-content">
                    <IoClose className="close-icon wh-2rem" onClick={e=>setShowMenu(false)}/>

                    <div className="mt-2 p-3">
                        <ul className="list-unstyled">
                            <li className="mb-2">
                                <a href="https://tquido.mx/contacto.php" className="text-decoration-none text-secondary fs-4 fw-bold" target="_blank">Contacto</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://tquido.mx/#faq" className="text-decoration-none text-secondary fs-4 fw-bold" target="_blank">Preguntas frecuentes</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://tquido.mx/facturacion.php" className="text-decoration-none text-secondary fs-4 fw-bold" target="_blank">Facturación</a>
                            </li>
                            <li className="mb-2">
                                <a href="https://tquido.mx/aviso-legal.php" className="text-decoration-none text-secondary fs-4 fw-bold" target="_blank">Aviso Legal</a>
                            </li>
                            <li className="mb-2">
                                <Link className="text-decoration-none text-secondary fs-4 fw-bold" to="/memberships">Solicita tu asistencia</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideMenu