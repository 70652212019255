import {Card, Col, Image, Row} from 'react-bootstrap'
import labMain from '../../img/descuentos/laboratorios/laboratorios.png'
import Slider from "react-slick";
import labOlab from '../../img/descuentos/laboratorios/olab-lab.png'
import chopoLab from '../../img/descuentos/laboratorios/chopo-lab.png'
import swissLab from '../../img/descuentos/laboratorios/swiss-lab.png'
import polabLab from '../../img/descuentos/laboratorios/polab-lab.png'
import polLab from '../../img/descuentos/laboratorios/polanco-lab.png'
import React from "react";

function Laboratorios(){

    const settings = {
        dots: true,
        speed: 800,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        rows: 2,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 1
              }
            }
        ]
    };

    return (
        <Row className="my-5">
            <Col xs="12" md="4">
                <div className="position-relative">
                    <label className="m-0 absolute-center text-descuento">Laboratorios</label>
                    <Image src={labMain} fluid/>
                    <div className="overlay"></div>
                </div>
            </Col>
            <Col xs="12" md="8">
                <Slider {...settings}>
                    <div>
                    <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                        <Card.Body className="vcentered-area">
                            <div className="d-flex align-items-center h-100p">
                                <div className="me-2">
                                    <Image src={labOlab} fluid className="mh-100px padding-5"/>
                                </div>
                                <div>
                                    <span className="text-primary-1 fs-08 fw-bold d-block lh-1">27% en laboratorios, gabinete y estudios especiales.</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    </div>
                    

                    <div>
                        <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                            <Card.Body className="vcentered-area">
                                <div className="d-flex align-items-center h-100p">
                                    <div className="me-2">
                                        <Image src={chopoLab} fluid className="mh-100px"/>
                                    </div>
                                    <div>
                                        <span className="text-primary-1 fs-08 fw-bold d-block lh-1">20% en laboratorios. 15% en gabinete. 5% estudios especiales.</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div>
                        <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                            <Card.Body className="vcentered-area">
                                <div className="d-flex align-items-center h-100p">
                                    <div className="me-2">
                                        <Image src={swissLab} fluid className="mh-100px"/>
                                    </div>
                                    <div>
                                        <span className="text-primary-1 fs-08 fw-bold d-block lh-1">27% en laboratorios, gabinete y estudios especiales.</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div>
                        <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                            <Card.Body className="vcentered-area">
                                <div className="d-flex align-items-center h-100p">
                                    <div className="me-2">
                                        <Image src={polabLab} fluid className="mh-100px"/>
                                    </div>
                                    <div>
                                        <span className="text-primary-1 fs-08 fw-bold d-block lh-1">Hasta 40 % dependiendo servicio.</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    
                    <div>
                        <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                            <Card.Body className="vcentered-area">
                                <div className="d-flex align-items-center h-100p">
                                    <div className="me-2">
                                        <Image src={polLab} fluid className="mh-100px"/>
                                    </div>
                                    <div>
                                        <span className="text-primary-1 fs-08 fw-bold d-block lh-1">27% hasta 50% en laboratorios, gabiente y estudios especiales.</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    
                </Slider>
            </Col>
        </Row>
    )

}

export default Laboratorios