//"https://api.doctor.video"
//"https://api-uat-ike.doctor.video"
//"https://api-qa-ike.doctor.video"
//"https://api-dev-ike.doctor.video"

export const URL_API = "https://api.doctor.video"

//"https://pwa-servicios-online.ikeasistencia.com"
//"https://uat-pwa.doctor.video"
//"https://qa-pwa.doctor.video"
//"https://dev-pwa.doctor.video"

export const URL_PAGE = "https://pwa-servicios-online.ikeasistencia.com"

//credenciales ike secure
export const USER_IKE_CONNECT = "pwauserapp@doctor.video"
export const PASSWORD_IKE_CONNECT = "!Sys73xrv21"