import Put from "../api/put";
import { UPDATE_APPOINTMENT_SIN_TOKEN } from "../api/routes";

export const setTimeAppointmentSinToken = (idCita, userType, isStart, arrVideoCall) =>{
    let index = arrVideoCall.findIndex(el=>el.userType===userType && el.initVideo === isStart);

    if(index < 0){
        let d = {
            id: idCita,
            videoCalls: [{
                id: '',
                initVideo: isStart,
                userType: userType
            }]
        }

        Put({url: UPDATE_APPOINTMENT_SIN_TOKEN, data: d, secure: false})
        .then(response=>{
            console.log(response)
        })
        .catch(error=>{
            console.log(error)
        })
    }    
}