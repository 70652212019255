import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {APPOINTMENT_DEMAND_LINK} from "../api/routes";
import NotAppovedAutoAgendar from "../components/cita/NotAppovedAutoAgendar";
import LoaderOndemand from "../components/loader/LoaderOndemand";
import ComponentModalErrorSever from "../components/util/ComponentModalErrorSever";
import useUserIke from "../hook/useUserIke";
import {getIpPublic} from "../utils/getIpPublic";
import usePatient from "../hook/usePatient";
import Post from "../api/post";

function RequestAppointment(){
    const { idEspecialidad } = useParams()
    const patient = usePatient()
    const userIKe = useUserIke()
    const history = useHistory()
    const [currentStatus, setCurrentStatus] = useState('loading')
    const [showModalError, setShowModalError] = useState(false)
    const [errorServer, setErrorServer] = useState(null)
    const [timeRequest, setTimerequest] = useState(900000)//900000
    const [waiting, setWaiting] = useState(false)

    useEffect(() => {
        let mounted = true;
        if(!userIKe[2] && userIKe[0] && mounted){
            searchAppointmentDemand()
        }
        return () => mounted = false;
    }, [userIKe[2]])
    //efecto para ir buscando disponibilidad por 15minutes    
    useEffect(()=>{
        if(waiting && timeRequest >= 0){
            const interval = setInterval(() => {
                setTimerequest(timeRequest-60000)
            }, 60000)
            if(timeRequest <= 0) {
                setTimerequest(0)
                setCurrentStatus("noApproved")
            } else {
                searchAppointmentDemand()
            }
            return () => clearInterval(interval);
        }
    }, [waiting, timeRequest])

    const searchAppointmentDemand = () =>{
        let mounted = true;
        getIpPublic()
        .then(resIp =>{
            if(mounted){
                let d = {"ipAddress":resIp}
                let url = `${APPOINTMENT_DEMAND_LINK}/${idEspecialidad}/${patient?.id}`
                Post({url: url, data: d, access_token: userIKe[0], secure: true})
                .then(response=>{
                    console.log(response)
                    if(response.data.data.approved){
                        setTimeout(()=>{
                            history.push(`/conference/${btoa(response.data.data.id)}`)
                        }, 5000)
                    } else {
                        setWaiting(true)
                    }
                })
                .catch(error=>{
                    setShowModalError(true)
                    if(error.response){
                        setErrorServer(error.response)
                    }
                })
            }
        })
        .catch(errIp=>{
            setShowModalError(true)
            if(errIp.response){
                setErrorServer(errIp.response)
            }
        })
        return () => mounted = false;
    }

    const handleClose = () => {
        setShowModalError(false)
        history.push('/menu')
    }

    const status = {
        loading: <LoaderOndemand idEspecialidad={idEspecialidad}/>,
        noApproved: <NotAppovedAutoAgendar 
                        idEspecialidad={idEspecialidad}
                        texto="El tiempo de espera para el médico asignado se ha agotado."/>
    }

    return (
        <>
            {
                status[currentStatus]
            }
            <ComponentModalErrorSever 
                show={showModalError}
                handleClose={handleClose}
                error={errorServer}
            />
        </>
        
    )
}

export default RequestAppointment