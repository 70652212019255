import React from "react"
import ContentLoader from "react-content-loader"

const HistoryAppointmentLoader = (props) => (
    <ContentLoader
        speed={1}
        width={400}
        height={150}
        viewBox="0 0 400 150"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="80" y="11" rx="3" ry="3" width="200" height="7" />
        <rect x="80" y="33" rx="0" ry="0" width="80" height="6" />
        <circle cx="34" cy="37" r="34" />
        <rect x="80" y="73" rx="0" ry="0" width="80" height="6" />
        <rect x="80" y="53" rx="0" ry="0" width="130" height="6" />
    </ContentLoader>
)

export default HistoryAppointmentLoader