import Countdown from "react-countdown";
import React from "react";
import * as moment from "moment";

function CountdowSimple({date, onClomplete, serverTime, setServerTime, clientTime, setClientTime, isReception}){

    const handleNow = () => {
        return serverTime;
    }

    const onTick = () =>{
        const now = Date.now();
        setServerTime(serverTime + (now - clientTime))
        setClientTime(now)
        if (isReception){
            let diff = date - now
            if (diff <= 300000)
                onClomplete()
        }
    }

    const renderer = ({ hours, minutes, seconds,completed }) => {
        return <span>{hours < 10 ? `0${hours}` : hours}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>        
    };

    return(
        <Countdown 
            date={date} 
            renderer={renderer} 
            onTick={onTick}
            now={handleNow}
            onComplete={onClomplete}
        />
    )
}

export default CountdowSimple