import React, {useEffect, useState} from "react";
import moment from "moment";
import 'moment/locale/es';
import {Button, Card, Col, Image, Modal, Row} from "react-bootstrap";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import "../../styles/container.css";
import {ADD_APPOINTMENT, SCHEDULES_BY_SPECIALTY} from "../../api/routes";
import useUserIke from "../../hook/useUserIke";
import Post from "../../api/post";
import {getIpPublic} from "../../utils/getIpPublic";
import ComponentModalErrorSever from "../util/ComponentModalErrorSever";
import ValidateAppointment from "../loader/ValidateAppointment";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import calendarSVGBlue from '../../img/svg/calendar-blue.svg'
import calendarSVGB from '../../img/svg/calendar.svg'
import SimpleLoad from "../loader/SimpleLoad";
import ModalSuccess from "../modals/ModalSuccess";


const ScheduleSelector = (props) => {
    const userIKe = useUserIke()
    const [horario, setHorario] = useState(null)
    const [fecha, setFecha] = useState(new Date())
    const [dateSelected, setDateSelected] = useState(null)
    const [startDay, setStartDay] = useState(0)
    const [indexSelectHor, setIndexSelectHor] = useState(null)
    const [showPaginate, setShowPaginate] = useState(true)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showModalServer, setShowModalServer] = useState(false)
    const [isSubmitting, setIsSubmiting] = useState(false)
    const [errorServer, setErrorServer] = useState(null)
    const [loadSchedule, setLoadSchedule] = useState(true)
    const [showSuccess, setShowSuccess] = useState(false)

    useEffect(() => {
        if (props?.speciality) {
            let mounted = true;
            if (!userIKe[2] && userIKe[0] && mounted) {
                setLoadSchedule(true)
                let url = `${SCHEDULES_BY_SPECIALTY}?specialityId=${props?.speciality.id}&dateIni=${moment(fecha).format("DD-MM-YYYY")}&dateEnd=${moment(fecha).format("DD-MM-YYYY")}&accountId=${props?.patient?.account?.id}`
                Post({url: url, data: {}, access_token: userIKe[0], secure: true})
                    .then(response => {
                        if (mounted) {
                            if (response?.data?.state) {
                                if (Object.keys(response?.data?.data?.response).length > 0) {
                                    let keys = Object.keys(response?.data?.data?.response)
                                    let obj = {
                                        key: keys[0],
                                        value: response.data?.data?.response[keys[0]]
                                    }
                                    setHorario(obj)
                                } else {
                                    let obj = {
                                        key: moment(fecha).format("YYYY-MM-DD"),
                                        value: []
                                    }
                                    setHorario(obj)
                                }
                            }
                            setLoadSchedule(false)
                        }
                    })
                    .catch(error => {
                        setLoadSchedule(false)
                        setHorario(null)
                        if(error){
                            setErrorServer(error)
                        }
                    })
            }
            return () => mounted = false;
        }
    }, [fecha, props?.speciality])

    const minusDay = n => {
        let nDays = startDay - n
        setStartDay(nDays)
        let newFecha = moment(fecha)
        newFecha.subtract(n, 'days')
        setFecha(newFecha)
        setIndexSelectHor(null)
    }

    const plusDay = n => {
        let nDays = startDay + n
        setStartDay(nDays)
        let newFecha = moment(fecha)
        newFecha.add(n, 'days')
        setFecha(newFecha)
        setIndexSelectHor(null)
    }

    const handleClose = () => setShowConfirm(false);
    const handleShow = () => setShowConfirm(true);

    const handleCloseModalServer = () => {
        setShowModalServer(false)
    }

    const selectTime = (it, i) => {
        setIndexSelectHor(i)
        let schedule = {
            scheduleType: "Online",
            doctor: null,
            hourInit: moment(it.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY HH:mm:ss"),
            candidateDoctors: it.doctors.map(e => e.id)
        }
        props?.setInitialData(prev => ({
            ...prev,
            hourInit: moment(it.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").format("DD-MM-YYYY HH:mm:ss"),
            schedule: schedule,
            doctorName: ``,
            especialidadName: props?.speciality.name,
            especialidadId: props?.speciality.id,
            id: props?.patient?.id,
            email: props?.patient?.email,
            name: props?.patient?.name,
            lastName: props?.patient?.lastName
        }))

        let dateFormat = moment(it.dateTimeInit, "YYYY-MM-DDTHH:mm:ss")
        setDateSelected(dateFormat.format("LLLL"))
        handleShow()
    }

    const updateAppointment = (appointmentParams) => {
        props.updateAppointment(appointmentParams);
    }

    const showAll = () => {
        setShowPaginate(!showPaginate)
    }

    const handleMakeAppointment = () => {
        handleClose()
        setIsSubmiting(true)
        if (!userIKe[2] && userIKe[0]) {
            getIpPublic()
                .then(resp => {
                    let data = {
                        appointmentStatus: "Pagada" ,
                        patient: {
                            id: props?.initialData.id,
                            email: props?.initialData.email
                        },
                        schedule: props?.initialData.schedule,
                        especialty: {
                            id: props?.initialData.especialidadId
                        },
                        linkPatient: props?.initialData.linkPatient,
                        legalWarnings: [{
                            acceptPatient: "approved",
                            ipAddress: resp,
                            legalWarningType: "InformedConsent"
                        }]
                    }

                    console.log(data)
                    Post({url: ADD_APPOINTMENT, data: data,access_token: userIKe[0], secure: true})
                        .then(response => {
                            if (response?.data?.state) {                                
                                setIsSubmiting(false)
                                setShowSuccess(true)
                                setTimeout(()=>{
                                    setShowSuccess(false)
                                    updateAppointment(response.data?.data)
                                }, 2000)
                            }
                        })
                        .catch(error => {
                            setShowModalServer(true)
                            setErrorServer(error?.response)
                            setIsSubmiting(false)
                        })

                })
                .catch(err => {
                    console.log(err)
                    setIsSubmiting(false)
                })
        }
    }

    const actionsFormatter = (cell, row, index) => {
        return (
            <div key={index}
                onClick={e => selectTime(row, index)}
            >{moment(row.hour, "HH:mm:ss").format("HH:mm")}</div>
        );
    }

    const columns = [
        {
            dataField: 'hour',
            text:'',
            align: 'center',
            formatter: actionsFormatter
        }
    ];

    const pageListRenderer = ({
                                  pages,
                                  onPageChange
                              }) => {
        const pageWithoutIndication = pages.filter(p => typeof p.page !== 'string');
        return (
            <div>
                {
                    pageWithoutIndication.map(p => (
                        <button key={p.page} className="btn btn-schedule-paginate" onClick={ () => onPageChange(p.page) }>{ p.page }</button>
                    ))
                }
                <button className="btn btn-schedule-paginate" onClick={showAll}>Ver todos</button>
            </div>
        );
    };

    const options = {
         sizePerPage: 5,
         hideSizePerPage: true,
         hidePageListOnlyOnePage: true,
         pageListRenderer
    };

    return (
        <>
            {showPaginate ?

                <Row>
                    <Col xs="12" md={{span: 3, offset: 2}} className="d-none d-md-block">
                        <Card className="card-primary card-default shadow-lg">
                            <Card.Body className="py-4">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="pe-4">
                                        <Image src={calendarSVGB} fluid className="calendarsvg-1" />
                                    </div>
                                    <div>
                                        <h4 className="text-white fs-4">Programar <br />consulta</h4>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs="12" md="5">
                        <Card className="shadow-lg p-0 card-schedules-booking bg-body rounded-1" key={`page_0`}>
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <div className="pe-4">
                                        <Image src={calendarSVGBlue} fluid className="calendarsvg-2" />
                                    </div>
                                    <div>
                                        <h4 className="text-primary-1 fs-6 m-0">Solicitud por agendamiento</h4>
                                    </div>
                                </div>
                                <div><small className="text-muted">Por favor elige el horario de tu preferencia</small></div>
                                <Row className="mt-4 position-relative">
                                    {loadSchedule && <SimpleLoad />}
                                    {
                                        horario &&
                                        <Col className="text-style">
                                            <Row className="align-items-center selector-date-schedule">
                                                <Col xs="2" md="2" className="text-center">{startDay > 0 &&
                                                <span className="bg-arrow" onClick={e => minusDay(1)}><BsChevronLeft/></span>}</Col>
                                                <Col xs="8" md="8" className="text-center">
                                                    <div className="text-center">
                                            <span
                                                className="d-block font-weight-bold-600">{moment(horario.key, "YYYY-MM-DD").format("ddd")}</span>
                                                        <span
                                                            className="font-size-08rem d-block">{moment(horario.key, "YYYY-MM-DD").format("DD MMM YYYY")}</span>
                                                    </div>
                                                </Col>
                                                <Col xs="2" md="2" className="text-center">{startDay !== 7 &&
                                                <span className="bg-arrow" onClick={e => plusDay(1)}><BsChevronRight/></span>}</Col>
                                            </Row>
                                            { horario.value.length === 0 ?
                                            <Card className="shadow-sm p-2 mb-3 mt-2 bg-body rounded-3 text-center" key={`sche_0`}>
                                                <Card.Body>
                                                    <h3>Lo sentimos!</h3>
                                                    <p>No hay horarios disponible para la fecha seleccionada</p>
                                                </Card.Body>
                                            </Card>
                                            :
                                            <BootstrapTable
                                                keyField="hour"
                                                data={ horario.value }
                                                columns={ columns }
                                                pagination={ paginationFactory(options) }
                                                wrapperClasses="tabel-single-hour"
                                            />}

                                        </Col>
                                    }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                :
                <Row>
                    <Col xs="12" md={{span: 3, offset: 2}} className="d-none d-md-block">
                        <Card className="card-primary card-default shadow-lg">
                            <Card.Body className="py-4">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="pe-4">
                                        <Image src={calendarSVGB} fluid className="calendarsvg-1" />
                                    </div>
                                    <div>
                                        <h4 className="text-white fs-4">Programar <br />consulta</h4>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs="12" md="5">
                        <Card className="shadow-lg p-0 card-schedules-booking bg-body rounded-1" key={`sche_0`}>
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <div className="pe-4">
                                        <Image src={calendarSVGBlue} fluid className="calendarsvg-2" />
                                    </div>
                                    <div>
                                        <h4 className="text-primary-1 fs-6 m-0">Solicitud por agendamiento</h4>
                                    </div>
                                </div>
                                <div><small className="text-muted">Por favor elige el horario de tu preferencia</small></div>
                                
                                <Row className="mt-4 position-relative">
                                    {loadSchedule && <SimpleLoad />}
                                    {
                                        horario &&
                                        <Col className="text-style">
                                            <Row className="align-items-center selector-date-schedule">
                                                <Col xs="2" md="2" className="text-center">{startDay > 0 &&
                                                <span className="bg-arrow" onClick={e => minusDay(1)}><BsChevronLeft/></span>}</Col>
                                                <Col xs="8" md="8" className="text-center">
                                                    <div className="text-center">
                                            <span
                                                className="d-block font-weight-bold-600">{moment(horario.key, "YYYY-MM-DD").format("ddd")}</span>
                                                        <span
                                                            className="font-size-08rem d-block">{moment(horario.key, "YYYY-MM-DD").format("DD MMM YYYY")}</span>
                                                    </div>
                                                </Col>
                                                <Col xs="2" md="2" className="text-center">{startDay !== 7 &&
                                                <span className="bg-arrow" onClick={e => plusDay(1)}><BsChevronRight/></span>}</Col>
                                            </Row>
                                            {
                                                horario.value.length === 0 ?
                                                    <Card className="shadow-sm p-2 mb-3 mt-5 bg-body rounded-3 text-center" key={`sche_0`}>
                                                        <Card.Body>
                                                            <h3>Lo sentimos!</h3>
                                                            <p>No hay horarios disponible para la fecha seleccionada</p>
                                                        </Card.Body>
                                                    </Card>
                                                    :
                                                    <div className="wrapper-hour mt-3">
                                                        {
                                                            horario.value.map((it, i) => (
                                                                <div
                                                                    key={i}
                                                                    className={`cursor-pointer box-hour ${i === indexSelectHor && 'box-hour-active'}`}
                                                                    onClick={e => selectTime(it, i)}
                                                                >{moment(it.hour, "HH:mm:ss").format("HH:mm")}</div>
                                                            ))
                                                        }
                                                    </div>
                                            }

                                        </Col>
                                    }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }

            <Modal show={showConfirm} onHide={handleClose} centered size="sm" className="border-0">
                <Modal.Body className="p-0">
                    <div className="text-center position-relative modal-container-content">
                        <h5 className="mt-2 mb-2 modal-title-content">{props?.speciality?.name}</h5>
                        <p className="modal-hour-content">{dateSelected}</p>
                        <Button className="link-oval bg-skyblue font-16 bold m-2" onClick={e => handleMakeAppointment()} block>Confirmar</Button>
                    </div>
                </Modal.Body>
            </Modal>
            {isSubmitting && <ValidateAppointment texto={"Espere por favor..."} />}
            <ComponentModalErrorSever
                show={showModalServer}
                handleClose={handleCloseModalServer}
                error={errorServer}
            />
            <ModalSuccess show={showSuccess} setShow={setShowSuccess} />
        </>
    );
};

export default ScheduleSelector;
