import { Form } from "react-bootstrap"

function PreguntaSeleccionIndividual({item, onHandleIndividualAnswer}){
    return(
        <>
                <label className="font-weight-bold-600 font-size-09rem d-block">{item.title}</label>
                <div className="d-flex flex-column">
                    {
                        item.options.map((it, i)=>(
                            <div className="item-encuenta-1 shadow-sm p-3 mb-2" key={i}>
                                <Form.Check
                                    className="font-size-08rem color-434343 font-weight-bold-600"
                                    type="radio"
                                    name={`radio${item.id}`}
                                    value={it}
                                    label={it}
                                    onChange={e=>onHandleIndividualAnswer(e.target.value, item)}
                                />
                            </div>
                        ))
                    }
                </div>
        </>
    )
}

export default PreguntaSeleccionIndividual