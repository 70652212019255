import { Button, Col, Container, Row } from "react-bootstrap"
import React from "react";

function InfoCard({texto}){


    return (
        <Container className="main pt-5rem">
            <Row>
                <Col className="text-center" xs="12" md={{span: 8, offset: 2}}>
                    <p>
                    {texto}
                    </p>
                    <Button variant="link" block onClick={() => window.location.reload()}>Volver a intentar</Button>
                </Col>
            </Row>
        </Container>
    )


}

export default InfoCard