import {Card, Col, Image, Row} from 'react-bootstrap'
import hospMain from '../../img/descuentos/hospitales/hospitales.png'
import Slider from "react-slick";
import durHosp from '../../img/descuentos/hospitales/durango-hosp.png'
import startHosp from '../../img/descuentos/hospitales/startmed-hosp.png'
import angelesHosp from '../../img/descuentos/hospitales/angeles-hosp.png'
import sanangelHosp from '../../img/descuentos/hospitales/sanangel-hosp.png'
import ginequitoHosp from '../../img/descuentos/hospitales/ginequito-hosp.png'
import dunantHosp from '../../img/descuentos/hospitales/dunant-hosp.png'
import React from "react";

function Hospitales(){

    const settings = {
        dots: true,
        speed: 800,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        rows: 2,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 1
              }
            }
        ]
    };

    return (
        <Row className="my-5">
            <Col xs="12" md="4">
                <div className="position-relative">
                    <label className="m-0 absolute-center text-descuento">Hospitales</label>
                    <Image src={hospMain} fluid/>
                    <div className="overlay"></div>
                </div>
            </Col>
            <Col xs="12" md="8">
                <Slider {...settings}>
                    <div>
                    <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                        <Card.Body className="vcentered-area">
                            <div className="d-flex align-items-center h-100p">
                                <div className="me-2">
                                    <Image src={durHosp} fluid className="mh-100px"/>
                                </div>
                                <div>
                                    <span className="text-primary-1 fs-08 fw-bold d-block lh-1">10% hospitalización. 10% en laboratorios, gabinete.</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    </div>
                    

                    <div>
                        <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                            <Card.Body className="vcentered-area">
                                <div className="d-flex align-items-center h-100p">
                                    <div className="me-2">
                                        <Image src={startHosp} fluid className="mh-100px"/>
                                    </div>
                                    <div>
                                        <span className="text-primary-1 fs-08 fw-bold d-block lh-1">Hasta 15%.</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div>
                        <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                            <Card.Body className="vcentered-area">
                                <div className="d-flex align-items-center h-100p">
                                    <div className="me-2">
                                        <Image src={angelesHosp} fluid className="mh-100px"/>
                                    </div>
                                    <div>
                                        <span className="text-primary-1 fs-08 fw-bold d-block lh-1">10% Hospitalización.</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div>
                        <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                            <Card.Body className="vcentered-area">
                                <div className="d-flex align-items-center h-100p">
                                    <div className="me-2">
                                        <Image src={sanangelHosp} fluid className="mh-100px"/>
                                    </div>
                                    <div>
                                        <span className="text-primary-1 fs-08 fw-bold d-block lh-1">10% Hospitalizacióno.</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    
                    <div>
                        <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                            <Card.Body className="vcentered-area">
                                <div className="d-flex align-items-center h-100p">
                                    <div className="me-2">
                                        <Image src={ginequitoHosp} fluid className="mh-100px"/>
                                    </div>
                                    <div>
                                        <span className="text-primary-1 fs-08 fw-bold d-block lh-1">Hasta 3%. 20% consulta de especialidad.</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div>
                        <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                            <Card.Body className="vcentered-area">
                                <div className="d-flex align-items-center h-100p">
                                    <div className="me-2">
                                        <Image src={dunantHosp} fluid className="mh-100px"/>
                                    </div>
                                    <div>
                                        <span className="text-primary-1 fs-08 fw-bold d-block lh-1">10% Hospitalización.</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    
                </Slider>
            </Col>
        </Row>
    )

}

export default Hospitales