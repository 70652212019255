import {Card, Col, Image, Row} from 'react-bootstrap'
import petMain from '../../img/descuentos/pets.jpg'
import Slider from "react-slick";
import React from "react";

function Pets(){

    const settings = {
        dots: true,
        speed: 800,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        rows: 4,
        appendDots: dots => (
            <div id="dots-custom">
                <ul style={{ margin: "5px" }}> {dots} </ul>
            </div>
    ),
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 4
              }
            }
        ]
    };

    let arrayListPets = {

        "rows": [
            {
                "c": [
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Animal Care"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "El Arca de Noe"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Frogos"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Mascotitas"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Clinica Veterianria Adoratrice"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Aguascalientes"
                    },
                    {
                        "v": "Hospital Veterinario Pets"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Baja California"
                    },
                    {
                        "v": "Ensenada"
                    },
                    {
                        "v": "Clínica Veterinaria Bahía"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Baja California"
                    },
                    {
                        "v": "Ensenada"
                    },
                    {
                        "v": "Clinica y Farmacia Veterinaria Rico"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Baja California"
                    },
                    {
                        "v": "Mexicali"
                    },
                    {
                        "v": "Central Veterinario"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Baja California"
                    },
                    {
                        "v": "Mexicali"
                    },
                    {
                        "v": "Servicios Veterinarios Especializados Vetco"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Baja California"
                    },
                    {
                        "v": "Tijuana"
                    },
                    {
                        "v": "Alexzander"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Baja California"
                    },
                    {
                        "v": "Tijuana"
                    },
                    {
                        "v": "LH"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Baja California "
                    },
                    {
                        "v": "Tijuana"
                    },
                    {
                        "v": "Unidad Veterinaria"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Baja California Sur"
                    },
                    {
                        "v": "La Paz"
                    },
                    {
                        "v": " Blanco y negro"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Baja California Sur"
                    },
                    {
                        "v": "La Paz"
                    },
                    {
                        "v": "Cuatro Molinos"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Baja California Sur"
                    },
                    {
                        "v": "La Paz"
                    },
                    {
                        "v": "Kora-Veterinaria"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Álvaro Obregón"
                    },
                    {
                        "v": "Animal Care Center"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Álvaro Obregón"
                    },
                    {
                        "v": "Especialidades Veterinarias"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Álvaro Obregón"
                    },
                    {
                        "v": "K-chorros Hospital Vet Especialidades  las Aguilas"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Álvaro Obregón"
                    },
                    {
                        "v": "Malvido"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Álvaro Obregón"
                    },
                    {
                        "v": "Medi Pet"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Azcapotzalco"
                    },
                    {
                        "v": "Peques"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Benito Juárez"
                    },
                    {
                        "v": "Del valle"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Benito Juárez"
                    },
                    {
                        "v": "Medican Center"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Benito Juárez"
                    },
                    {
                        "v": "Pet Center"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Coyoacán"
                    },
                    {
                        "v": "Centro Veterinario del Sur "
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Coyoacán"
                    },
                    {
                        "v": "Consultorio Veterinario  Zoo "
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Coyoacán"
                    },
                    {
                        "v": "D´animal"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Coyoacán"
                    },
                    {
                        "v": "El Perro Verde"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Coyoacán"
                    },
                    {
                        "v": "Mundo Veterinario"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Coyoacán"
                    },
                    {
                        "v": "Pets in the Sky"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Coyoacán"
                    },
                    {
                        "v": "Snoopy"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Coyoacán"
                    },
                    {
                        "v": "Villa  Huesito"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Cuauhtémoc"
                    },
                    {
                        "v": " Emergencias Veterinarias"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Cuauhtémoc"
                    },
                    {
                        "v": "Amsterdam"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Cuauhtémoc"
                    },
                    {
                        "v": "Centro Medico Veterinario Roma"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Cuauhtémoc"
                    },
                    {
                        "v": "Condesa Pet-Center"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Cuauhtémoc"
                    },
                    {
                        "v": "Roacho"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Gustavo A. Madero"
                    },
                    {
                        "v": "Dr. Guillermo Lefranc"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Gustavo A. Madero"
                    },
                    {
                        "v": "Especialidades Inguaran"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Iztacalco"
                    },
                    {
                        "v": " Billmax"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Iztacalco"
                    },
                    {
                        "v": "Pet´s"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Iztapalapa"
                    },
                    {
                        "v": " HHG"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Iztapalapa"
                    },
                    {
                        "v": "Animalia"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Iztapalapa"
                    },
                    {
                        "v": "Cascada"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Magdalena Contreras "
                    },
                    {
                        "v": "Alfa"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Miguel Hidalgo"
                    },
                    {
                        "v": "Consultorio Veterinario Garcia  Alcaraz"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Miguel Hidalgo"
                    },
                    {
                        "v": "Servicios Veterinarios Darwin"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Tlalpan"
                    },
                    {
                        "v": "Dr. Albiter"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Tlalpan"
                    },
                    {
                        "v": "J.U. clinica y estetica  Vet.S.C."
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Tlalpan"
                    },
                    {
                        "v": "La Rana"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Tlalpan"
                    },
                    {
                        "v": "Royal Pets"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Xochimilco"
                    },
                    {
                        "v": "Kan-kan"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "CDMX"
                    },
                    {
                        "v": "Xochimilco"
                    },
                    {
                        "v": "Roga"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Chiapas"
                    },
                    {
                        "v": "Tuxtla Gutiérrez"
                    },
                    {
                        "v": "Grupo Canes"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Chihuahua"
                    },
                    {
                        "v": "Chihuahua"
                    },
                    {
                        "v": "Animal Pet"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Chihuahua"
                    },
                    {
                        "v": "Chihuahua"
                    },
                    {
                        "v": "Dr. Lincón"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Chihuahua"
                    },
                    {
                        "v": "Chihuahua"
                    },
                    {
                        "v": "El Mundo de  los Animales"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Chihuahua"
                    },
                    {
                        "v": "Chihuahua"
                    },
                    {
                        "v": "Veterinaria la Sierra"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Coahuila"
                    },
                    {
                        "v": "Saltillo"
                    },
                    {
                        "v": "El Palacio de las Mascotas"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Coahuila"
                    },
                    {
                        "v": "Torreón"
                    },
                    {
                        "v": "Caniche"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Coahuila"
                    },
                    {
                        "v": "Torreón"
                    },
                    {
                        "v": "Mr. French´s"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Colima"
                    },
                    {
                        "v": "Colima"
                    },
                    {
                        "v": "Mundo Animal"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Colima"
                    },
                    {
                        "v": "Colima"
                    },
                    {
                        "v": "Umeve"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Durango"
                    },
                    {
                        "v": "Durango"
                    },
                    {
                        "v": "Clinica Hospital Veterinario"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Durango"
                    },
                    {
                        "v": "Durango"
                    },
                    {
                        "v": "Las Americas"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Durango"
                    },
                    {
                        "v": "Durango"
                    },
                    {
                        "v": "Policlina Veterinaria"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Coacalco de Berriozabal"
                    },
                    {
                        "v": "Healthy Pets"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Cuautitlan Izcalli"
                    },
                    {
                        "v": "Vet Pet"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Cuautitlan Izcalli"
                    },
                    {
                        "v": "Xzootic"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Ecatepec de Morelos"
                    },
                    {
                        "v": "Hospital Veterinario Pet World 24 hrs"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Huixquilucan"
                    },
                    {
                        "v": "Clinica  Veterinaria Interlomas"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Naucalpan"
                    },
                    {
                        "v": "Centro Medico Veterinario Bulldogs"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Naucalpan"
                    },
                    {
                        "v": "Centro Veterinario Cima"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Naucalpan"
                    },
                    {
                        "v": "Pet Memorial"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Naucalpan"
                    },
                    {
                        "v": "Super Alfa"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Naucalpan "
                    },
                    {
                        "v": "Pet Planet"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Nezahualcoyotl"
                    },
                    {
                        "v": "Casa Alejos"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Tepotzotlán"
                    },
                    {
                        "v": "Tepotzotlan"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Texcoco"
                    },
                    {
                        "v": "Centro Veterinario Texcoco"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Tlalnepantla de Baz"
                    },
                    {
                        "v": "Cat´s and Dog´s"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Tlalnepantla de Baz"
                    },
                    {
                        "v": "Clinica y Estetica Akela"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Tlalnepantla de Baz"
                    },
                    {
                        "v": "Hospital Veterinario de Especialidades Ceylán"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Toluca"
                    },
                    {
                        "v": "HiDoc"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Toluca"
                    },
                    {
                        "v": "Hospital Veterinario de Especialidades"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Toluca"
                    },
                    {
                        "v": "Mister Kan"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Toluca "
                    },
                    {
                        "v": "Zoomundo"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Estado de México"
                    },
                    {
                        "v": "Zumpango "
                    },
                    {
                        "v": "Zumascota"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Guanajuato"
                    },
                    {
                        "v": "Celaya"
                    },
                    {
                        "v": "Ciudad Mascotas"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Guanajuato"
                    },
                    {
                        "v": "Celaya"
                    },
                    {
                        "v": "Jardin de la Fauna"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Guanajuato"
                    },
                    {
                        "v": "Irapuato"
                    },
                    {
                        "v": "Centro Veterinario Villas"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Guanajuato"
                    },
                    {
                        "v": "Irapuato"
                    },
                    {
                        "v": "El Mundo de los animales"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Guanajuato"
                    },
                    {
                        "v": "León"
                    },
                    {
                        "v": "Animal Zone"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Guanajuato"
                    },
                    {
                        "v": "León"
                    },
                    {
                        "v": "Chango´s Pet"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Guanajuato"
                    },
                    {
                        "v": "León"
                    },
                    {
                        "v": "Clinica  Veterinaria Shamavi"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Guanajuato"
                    },
                    {
                        "v": "León"
                    },
                    {
                        "v": "Sepulveda "
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Hidalgo"
                    },
                    {
                        "v": "Pachuca"
                    },
                    {
                        "v": "Hospital Veterinario Gómez"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Jalisco"
                    },
                    {
                        "v": "Guadalajara"
                    },
                    {
                        "v": "Mascotitas"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Jalisco"
                    },
                    {
                        "v": "Zapópan"
                    },
                    {
                        "v": "Maskottchen"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Jalisco "
                    },
                    {
                        "v": "Guadalajara"
                    },
                    {
                        "v": "El Arca de Noé"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Jalisco"
                    },
                    {
                        "v": "Guadalajara"
                    },
                    {
                        "v": "Pom\u2019s Mania"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Jalisco"
                    },
                    {
                        "v": "Zapópan"
                    },
                    {
                        "v": "Dr. Pet"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Michoacán"
                    },
                    {
                        "v": "Morelia"
                    },
                    {
                        "v": "Pupet"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Morelos"
                    },
                    {
                        "v": "Cuernavaca"
                    },
                    {
                        "v": "Clinica Clivem"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Nayarit"
                    },
                    {
                        "v": "Tepic"
                    },
                    {
                        "v": "San Bej"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Nuevo León"
                    },
                    {
                        "v": "Monterrey"
                    },
                    {
                        "v": "Del  Rosario"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Nuevo León"
                    },
                    {
                        "v": "Monterrey"
                    },
                    {
                        "v": "Dr. Serna"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Nuevo León"
                    },
                    {
                        "v": "Monterrey"
                    },
                    {
                        "v": "Hospital  Med  for Pet"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Puebla"
                    },
                    {
                        "v": "Puebla"
                    },
                    {
                        "v": "Dres.y Sahuesos"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Puebla"
                    },
                    {
                        "v": "Puebla"
                    },
                    {
                        "v": "Centro Veterinario La Paz"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Puebla"
                    },
                    {
                        "v": "Puebla"
                    },
                    {
                        "v": "MVZ Ventura Morales"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Querétaro"
                    },
                    {
                        "v": "Querétaro"
                    },
                    {
                        "v": "  Dr.Solorio"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Querétaro"
                    },
                    {
                        "v": "Querétaro"
                    },
                    {
                        "v": "Animal´S"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Querétaro"
                    },
                    {
                        "v": "Querétaro"
                    },
                    {
                        "v": "Central veterinario"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Querétaro"
                    },
                    {
                        "v": "Querétaro"
                    },
                    {
                        "v": "Mundo de mascotas"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Querétaro"
                    },
                    {
                        "v": "Querétaro"
                    },
                    {
                        "v": "Urgencias Veterinarias"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Quintana Roo"
                    },
                    {
                        "v": "Cancún"
                    },
                    {
                        "v": "Kolitas clinica veterinaria  y spa"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Quintana Roo"
                    },
                    {
                        "v": "Cancún"
                    },
                    {
                        "v": "Mundo  Mascotas Cancun"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Quintana Roo"
                    },
                    {
                        "v": "Cancún"
                    },
                    {
                        "v": "Pet´s Veterinaria Estetica y Clinica"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "San Luis Potosí"
                    },
                    {
                        "v": "San Luis Potosí"
                    },
                    {
                        "v": "More pets"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Sinaloa"
                    },
                    {
                        "v": "Mazatlán"
                    },
                    {
                        "v": "Veterinaria Paws Of Love Mazatlán"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Sonora"
                    },
                    {
                        "v": "Hermosillo"
                    },
                    {
                        "v": "Hospital Veterinario mascotas"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Sonora "
                    },
                    {
                        "v": "Hermosillo "
                    },
                    {
                        "v": "Animal Kits"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Tabasco "
                    },
                    {
                        "v": "Villahermosa "
                    },
                    {
                        "v": "Centro Medico Veterinario"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Tabasco"
                    },
                    {
                        "v": "Villahermosa"
                    },
                    {
                        "v": "Del Grijalva"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Tabasco"
                    },
                    {
                        "v": "Villahermosa"
                    },
                    {
                        "v": "Especialidades Veterinarias"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Tabasco"
                    },
                    {
                        "v": "Villahermosa"
                    },
                    {
                        "v": "Servicios Medicos  Veterinarios  del Sureste "
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Tamaulipas"
                    },
                    {
                        "v": "Tampico"
                    },
                    {
                        "v": "Hospital Veterinario San Lázaro"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Tamaulipas"
                    },
                    {
                        "v": "Reynosa"
                    },
                    {
                        "v": "Matus Clinica/Hospital para  Mascotas"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Tlaxcala"
                    },
                    {
                        "v": "Tlaxcala"
                    },
                    {
                        "v": "Animal Hospital"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Yucatán"
                    },
                    {
                        "v": "Mérida"
                    },
                    {
                        "v": "Hospital Veterinario  Carbajal"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Yucatán"
                    },
                    {
                        "v": "Mérida"
                    },
                    {
                        "v": "Jungla"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Yucatán"
                    },
                    {
                        "v": "Mérida"
                    },
                    {
                        "v": "Malaga "
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Yucatán"
                    },
                    {
                        "v": "Mérida"
                    },
                    {
                        "v": "Planned pethood"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Yucatán"
                    },
                    {
                        "v": "Mérida"
                    },
                    {
                        "v": "Vet Care Center"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Zacatecas"
                    },
                    {
                        "v": "Zacatecas"
                    },
                    {
                        "v": "Muppets"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Chihuahua"
                    },
                    {
                        "v": "Chihuahua"
                    },
                    {
                        "v": "Veterinaria Gordillo Urgencias "
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Coahuila"
                    },
                    {
                        "v": "Torreón"
                    },
                    {
                        "v": "Veterinaria Sumano"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Coahuila"
                    },
                    {
                        "v": "Torreón"
                    },
                    {
                        "v": "Veterinaria Cevecom"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Nuevo León"
                    },
                    {
                        "v": "Monterrey"
                    },
                    {
                        "v": "pets life&care suc madeira"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Nuevo León"
                    },
                    {
                        "v": "Apodaca "
                    },
                    {
                        "v": "Vterinaria Albeitar"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Sinaloa "
                    },
                    {
                        "v": "Culiacan"
                    },
                    {
                        "v": "Hospital Veterinario Mascota Palacio"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Michoacán"
                    },
                    {
                        "v": "Morelia"
                    },
                    {
                        "v": "Polo Vet"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Nuevo León"
                    },
                    {
                        "v": "Montemorelos "
                    },
                    {
                        "v": "Farmacia y Clinica Veterinaria Safari"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Nuevo León"
                    },
                    {
                        "v": "Monterrey"
                    },
                    {
                        "v": "Happy Dog"
                    },
                    null,
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            },
            {
                "c": [
                    {
                        "v": "Nuevo León"
                    },
                    {
                        "v": "Monterrey"
                    },
                    {
                        "v": "Imagen y Salud"
                    },
                    {
                        "v": "Sin Logo"
                    },
                    {
                        "v": "Desde 5% dependiendo Servicio. Consulta Veterninaria costo Preferencial. "
                    },
                    {
                        "v": null
                    }
                ]
            }
        ]
    }


    return (
        <>
        <Row className="mt-4 mb-4">
            <Col xs="12" md="4">
                <div className="position-relative">
                    <label className="m-0 absolute-center text-descuento">Mascotas</label>
                    <Image src={petMain} fluid/>
                    <div className="overlay"></div>
                </div>
            </Col>
            <Col xs="12" md="8">
                <Slider {...settings} className="dots-custom" >
                    {
                        arrayListPets.rows.map((it, i) => (
                            <div key={i}>
                                <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                                    <Card.Body className="vcentered-area">
                                        <div className="d-flex align-items-center h-100p">
                                            {/*<div className="me-2">*/}
                                            {/*    <Image src={arcaNoe} fluid className="mh-100px"/>*/}
                                            {/*</div>*/}
                                            <div>
                                                <span className="text-primary-1 fs-08 fw-bold d-block lh-1">{it.c[2].v}, {it.c[1].v}, {it.c[0].v}</span>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))
                    }
                    
                </Slider>
            </Col>
        </Row>
        <Row className="mt-4 mb-4">
            <Col xs="12" md="8">

            </Col>
        </Row>
        </>
    )

}

export default Pets