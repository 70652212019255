import React from "react";
import Row from "react-bootstrap/Row";
import {Button, Card, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import Slider from "react-slick";

function MenuIndividual(props){

    const hasEspeciality = (idParams) => {
        let result = false
        let exists = props?.patient?.account?.specialtyDurationList?.filter( item => item.especialty?.id === idParams)
        if(exists) {
            if(exists.length !== 0)
                result = true
        }

        return result
    }

    const settingsMenu = {
        dots: true,
        speed: 800,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
       <>
           <Row>
               <Col>
                   <Slider {...settingsMenu}>
                       <div>
                           <Card className="shadow-sm m-1 bg-body rounded-0 card-ambulance position-relative">
                               <Card.Body className="vcentered-area text-center">
                                   <a href="tel:5550155071,1,1" className="text-decoration-none">
                                       <div className="mb-2">
                                           <i className='icomoon icon-F__Ambulancia color-ambulance font-size-30px wh-24px m-auto' />
                                       </div>
                                       <div className="mb-3 h-30px">
                                           <h6 className="text-center text-primary-1 m-0 color-ambulance">Ambulancia por emergencia</h6>
                                       </div>
                                   </a>
                               </Card.Body>
                           </Card>
                       </div>
                       <div>
                           <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                               <Card.Body className="vcentered-area text-center">
                                   <a href="tel:5550155071,1,7" className="text-decoration-none">
                                       <div className="mb-2">
                                           <i className='icomoon icon-F__Hospitalizacion1 color-blue-secundary font-size-30px wh-24px' />
                                       </div>
                                       <div className="mb-3 h-30px">
                                           <h6 className="text-center text-primary-1 m-0">Apoyo por hospitalización</h6>
                                       </div>
                                   </a>
                               </Card.Body>
                           </Card>
                       </div>

                       <div>
                           <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                               <Card.Body className="vcentered-area text-center">
                                   <a href="tel:5550155071,1,3" className="text-decoration-none">
                                       <div className="mb-2">
                                           <i className='icomoon icon-F__Especialista color-blue-secundary font-size-30px wh-24px m-auto' />
                                       </div>
                                       <div className="mb-3 h-30px">
                                           <h6 className="text-center text-primary-1 m-0">Consulta en consultorio con pediatra e internista</h6>
                                       </div>
                                   </a>
                               </Card.Body>
                           </Card>
                       </div>
                       <div>
                           <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                               <Card.Body className="vcentered-area text-center">
                                   <a href="tel:5550155071,1,3" className="text-decoration-none">
                                       <div className="mb-2">
                                           <i className='icomoon icon-F__MediDomicilio color-blue-secundary font-size-30px wh-24px m-auto' />
                                       </div>
                                       <div className="mb-3 h-30px">
                                           <h6 className="text-center text-primary-1 m-0">Médico general a domicilio</h6>
                                       </div>
                                   </a>
                               </Card.Body>
                           </Card>
                       </div>
                       <div>
                           <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                               <Card.Body className="vcentered-area text-center">
                                   <a href="tel:5550155071,1,3" className="text-decoration-none">
                                       <div className="mb-2">
                                           <i className='icomoon icon-F__EnvioMedicamento color-blue-secundary font-size-30px wh-24px m-auto' />
                                       </div>
                                       <div className="mb-3 h-30px">
                                           <h6 className="text-center text-primary-1 m-0">Envío de medicamentos</h6>
                                       </div>
                                   </a>
                               </Card.Body>
                           </Card>
                       </div>
                       <div>
                           <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                               <Card.Body className="vcentered-area text-center">
                                   <a href="tel:5550155071,1,3" className="text-decoration-none">
                                       <div className="mb-2">
                                           <i className='icomoon icon-F__Honorarios color-blue-secundary font-size-30px wh-24px m-auto' />
                                       </div>
                                       <div className="mb-3 h-30px">
                                           <h6 className="text-center text-primary-1 m-0">Honorarios médicos</h6>
                                       </div>
                                   </a>
                               </Card.Body>
                           </Card>
                       </div>
                       <div>
                           <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                               <Card.Body className="vcentered-area text-center">
                                   <a href="tel:5550155071,1,7" className="text-decoration-none">
                                       <div className="mb-2">
                                           <i className='icomoon icon-F__Honorarios color-blue-secundary font-size-30px wh-24px m-auto' />
                                       </div>
                                       <div className="mb-3 h-30px">
                                           <h6 className="text-center text-primary-1 m-0">Indemnización y asistencia funeraria</h6>
                                       </div>
                                   </a>
                               </Card.Body>
                           </Card>
                       </div>
                       {(props?.patient?.account?.clcuentaIke === 2564 ||
                           props?.patient?.account?.clcuentaIke === 2563) &&
                       <div>
                           <Card className="shadow-sm m-1 bg-body rounded-0 card-menu position-relative">
                               <Card.Body className="vcentered-area text-center">
                                   <a href="tel:5550155071,1,3" className="text-decoration-none">
                                       <div className="mb-2">
                                           <i className='icomoon icon-F__cuidados-domiciliados color-blue-secundary font-size-30px wh-24px m-auto' />
                                       </div>
                                       <div className="mb-3 h-30px">
                                           <h6 className="text-center text-primary-1 m-0">Cuidados domiciliarios</h6>
                                       </div>
                                   </a>
                               </Card.Body>
                           </Card>
                       </div>}
                   </Slider>
               </Col>
           </Row>
           <Row className="mt-5">
               { hasEspeciality(54) &&
               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__MedicoOnline font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Orientación médica</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5550155071,1,3" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="mb-2">
                                       <Link to="/book-appointment/54" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__agendar font-size-20px' /> Agendar videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                                   <div>
                                       <Link to="/request-appointment/54" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Reloj font-size-20px' /> Videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>
               }
               { hasEspeciality(67) &&
               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__Psicologica_TQ font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Orientación psicológica</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5550155071,1,4" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="">
                                       <Link to="/book-appointment/67" className="text-decoration-none ">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__agendar font-size-20px' /> Agendar videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>
               }
               { hasEspeciality(59) &&
               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__Nutricional_TQ font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Orientación nutricional</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5550155071,1,5" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="">
                                       <Link to="/book-appointment/59" className="text-decoration-none ">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__agendar font-size-20px' /> Agendar videoconsulta
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>
               }


               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Card.Body>
                           <div>
                               <i className='icomoon icon-F__DescuentosYReferencias font-size-24px color-blue-secundary' />
                           </div>
                           <Row>
                               <Col xs="5" md="5">
                                   <h6 className="text-primary-1">Descuentos y referencias médicas</h6>
                               </Col>
                               <Col xs="7" md="7">
                                   <div className="mb-2">
                                       <a href="tel:5550155071,1,3" className="text-decoration-none">
                                           <Button variant="outline-primary" className="border-primary-2 text-primary-2 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__Telefono font-size-20px' /> LLamar
                                           </Button>
                                       </a>
                                   </div>
                                   <div className="">
                                       <Link to={{pathname:'/discounts', phoneNumber: '5550155071,1,3' }} className="text-decoration-none ">
                                           <Button variant="outline-primary" className="border-primary-1 text-primary-1 rounded-0 p-1 text-start">
                                               <i className='icomoon icon-F__listado font-size-20px' /> Descuentos y referencias médicas
                                           </Button>
                                       </Link>
                                   </div>
                               </Col>
                           </Row>
                       </Card.Body>
                   </Card>
               </Col>
               <Col xs="12" md="6">
                   <Card className="shadow-sm bg-body rounded-0 card-menu-down position-relative mb-3">
                       <Link to="/check-up" className="text-decoration-none">
                           <Card.Body>
                               <div>
                                   <i className='icomoon icon-F__Checkup font-size-30px color-blue-secundary' />
                               </div>
                               <Row>
                                   <Col xs="5" md="5">
                                       <h6 className="text-primary-1">Check up médico</h6>
                                   </Col>
                               </Row>
                           </Card.Body>
                       </Link>

                   </Card>
               </Col>
           </Row>
       </>
    )
}

export default MenuIndividual