import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import "../../styles/container.css";
import moment from "moment";
import CountdowSimple from "../utils/CountdowSimple";

const LobyCita = (props) => {

    const [startMeeting, setStartMeeting] = useState(null)
    const [serverTime, setServerTime] = useState(null)
    const [clientTime, setClientTime] = useState(null)

    useEffect(()=>{
        let min = props?.appointment?.scheduleDTO?.minutesAppointment*60000
        setServerTime(moment(props?.appointment?.currentTime, "DD/MM/YYYY HH:mm:ss").valueOf())
        setClientTime(Date.now())
        setStartMeeting(moment(props?.appointment?.scheduleDTO?.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").valueOf())
    }, [])

  return (
      <Container className="main pt-5rem">
        <Row className="mb-1">
          <Col className="text-center">
            <h1 className="color-blue-primary m-0 fs-1">
              SOLICITUD POR
            </h1>
            <h1 className="color-blue-primary letter-search fs-5">
              AGENDAMIENTO
            </h1>
          </Col>
        </Row>
        <Row className="mb-3 mt-1">
          <Col className="text-center">
              <span
                  className="bg-bluerey-asis text-white d-flex justify-content-center align-items-center wh-80 m-auto rounded-circle">
                  <i className="icomoon icon-F__Online2 font-40"/>
              </span>
          </Col>
        </Row>

        <Row className="mt-4">
            <Col className="text-center" xs="12" md="6">
                <Card className="shadow-lg mb-3 rounded-3 text-center bg-blue-card color-white mt-1">
                    <Card.Body>
                        <div className="bg-blue-card color-white ">
                            <p className="mb-0 font-20 bold color-white">Tu cita comienza en:</p>
                            <p className="mb-0 font-40 bold color-white">
                                {startMeeting &&
                                <CountdowSimple
                                    date={startMeeting}
                                    serverTime={serverTime}
                                    setServerTime={setServerTime}
                                    clientTime={clientTime}
                                    setClientTime={setClientTime}
                                    onClomplete={props?.onCompleteTimeStart}
                                    isReception={true}
                                />}
                            </p>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

      <Row className="mt-4">
          <Col className="text-center" xs="12" md="6">
              <div className="center mt-0">
                  <p className="mb-0 font-20 bold color-blue-secundary">Si necesitas atención inmediata<br /> comunicate con nosotros</p>
                  <br />
                  <a className="btn btn-primary font-16 bold m-2 py-2 px-4 mt-2" href={`tel:${props?.appointment?.scheduleDTO?.doctor?.phone}`}>Llamar al médico</a>
              </div>
          </Col>
      </Row>
      </Container>
  );
};

export default LobyCita;
