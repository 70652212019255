import { Col, Form, Row } from "react-bootstrap"

function PreguntaOpcionMultiple({t, item, onHandleMultipleAnswer}){
    return(
        <>
        <div>
            <label className="font-weight-bold-600 font-size-09rem d-block mb-0">{item.title}</label>
            <small className="mb-3 d-block">Seleccionar todas las que correspondan</small> 
        </div>
        <Row>
            {
                item.options.map((it,i)=>(
                    <Col key={i} xs="12" md="6">
                        <div className="item-encuenta-1 shadow-sm p-3 mb-2">
                            <Form.Check
                                className="font-size-08rem color-434343 font-weight-bold-600"
                                type="checkbox"
                                value={it}
                                label={it}                               
                                onClick={e=>{
                                    onHandleMultipleAnswer(e.target.value, e.target.checked, item)
                                }}
                            />
                        </div>
                    </Col>
                ))
            }
        </Row>
        </>
    )
}

export default PreguntaOpcionMultiple