import {Card, Col, Image, Row} from 'react-bootstrap'
import farmMain from '../../img/descuentos/farmacias/farmacias.png'
import Slider from "react-slick";
import espeFarm from '../../img/descuentos/farmacias/especializadas-farm.png'
import daroxFarm from '../../img/descuentos/farmacias/darox-farm.png'
import React from "react";

function Farmacias(){

    const settings = {
        dots: true,
        speed: 800,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
        ]
    };

    return (
        <Row className="my-5">
            <Col xs="12" md="4">
                <div className="position-relative">
                    <label className="m-0 absolute-center text-descuento">Farmacias</label>
                    <Image src={farmMain} fluid/>
                    <div className="overlay"></div>
                </div>
            </Col>
            <Col xs="12" md="8">
                <Slider {...settings}>
                    <div>
                    <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                        <Card.Body className="vcentered-area">
                            <div className="d-flex align-items-center h-100p">
                                <div className="me-2">
                                    <Image src={espeFarm} fluid className="mh-100px"/>
                                </div>
                                <div>
                                    <span className="text-primary-1 fs-08 fw-bold d-block lh-1">Hasta 45% precio máximo al público sobre medicamentos de cuadro básico.</span>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    </div>
                    

                    <div>
                        <Card className="shadow-sm m-1 bg-body rounded-0 card-descuentos position-relative">
                            <Card.Body className="vcentered-area">
                                <div className="d-flex align-items-center h-100p">
                                    <div className="me-2">
                                        <Image src={daroxFarm} fluid className="mh-100px"/>
                                    </div>
                                    <div>
                                        <span className="text-primary-1 fs-08 fw-bold d-block lh-1">30% precio máximo al público en medicamentos patente y 40% genéricos.</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    
                </Slider>
            </Col>
        </Row>
    )

}

export default Farmacias