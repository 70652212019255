import React, {useEffect, useState} from "react";
import imgCalendarStars from "../../img/principales/confirm-cita.png";
import {Card, Image} from "react-bootstrap";
import "../../styles/container.css";
import moment from "moment";
import {Link} from "react-router-dom";
import {NAV_PATIENT_PROFILE} from "../../api/routes";

const ConfirmSuccess = (appointment) => {
    const [state, setState] = useState({});

    useEffect(() => {
        return () => { setState({});  };
    }, []);

  return (
    <>
        <Card className="shadow p-2 mb-2 bg-body rounded-3 text-center">
            <Card.Body>
                <Image src={imgCalendarStars} width='170px' className='m-auto'/>
                <p className="font-40 color-bluerey w-700 mb-2">¡Listo!</p>
                {appointment?.appointment?.scheduleDTO?.doctor?.gender === "Male" ?
                    <p className="font-16 color-gray bold mt-3 mb-0">Has creado una <span className='color-aquarium'>cita online</span> con Dr.</p>
                    :
                    <p className="font-16 color-gray bold mt-2 mb-0">Has creado una  <span className='color-aquarium'>cita online</span> con la Dra.</p>
                }
                <p className="font-16 color-gray bold text-uppercase mb-0 mt-1">{appointment?.appointment?.scheduleDTO?.name_doctor}</p>
                <p className="font-18 color-gray-strong bold mb-3 mt-1">{moment(appointment?.appointment?.scheduleDTO?.dateTimeInit, "YYYY-MM-DDTHH:mm:ss").format("LLLL")}</p>

                <Link to={`/${NAV_PATIENT_PROFILE}`} className="link-oval bg-skyblue font-16 bold m-0">Ir a mi historial</Link>
            </Card.Body>
        </Card>
    </>
  );
};

export default ConfirmSuccess;
